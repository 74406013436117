<template>
    <div :class="customClass">
        <div class="warning_ring">
            <span>弱势</span>
            <span>强势</span>
            <div class="pointer_holder">
                <i ref="arrow"></i>
            </div>
        </div>
        <div class="warning_txt">
            <span class="warning_type">{{ data.sTypeText }}</span>
            <span class="warning_desc">{{ data.sDescText }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ['customClass'],
    data: function() {
        return {
            data: {},
        }
    },
    methods: {
        init(commandData) {
            const _that = this
            _that.data = commandData.data.data
            setTimeout(function(){
                _that.$refs.arrow.style.cssText = `transform: rotate(${1.8 * _that.data.fValue - 90}deg); transition: all 1s`
            },300);
        }
    }
}
</script>