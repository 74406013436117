<template>
  <div id="checkMarket">
    <div class="pages hidden" id="checkMarketPages">
      <div class="page_title"><span class="text hidden" id="checkMarketText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refCheckMarketBarHolder" v-if="barVisible">
        <Bar ref="refCheckMarketBarChart" id="barChart" />
        <ul class="barChartDes">
          <li v-for="(item, index) in totalData" :key="index">
            <div>
              <span>{{ item.name }}</span>
              <span>{{ item.value }}只</span>
            </div>
            <div>
              <span>占比</span>
              <span>{{ item.rate }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="statVisible">
        <Stat ref="refCheckMarketStat" class="statChart" />
      </div>
      <div ref="refCheckMarketLineHolder" v-if="lineVisible">
        <double-line-chart ref="refCheckMarketLine" id="checkMarketLine" customClass="link_chart" />
      </div>
      <div ref="refCheckMarketTableHolder" v-if="tableVisible">
        <table-chart ref="refCheckMarketTable" id="checkMarketTable" customClass="quote_table_chart" />
      </div>
      <div ref="refCheckMarketCircleHolder" v-if="circleVisible">
        <SemiCircle ref="refCheckMarketCircle" class="circleChart" />
      </div>
      <div ref="refCheckMarketCapitalHolder" v-if="capitalVisible" class="capital">
        <div>
          <h3>两市融资余额趋势</h3>
          <p>{{ capitaldesc.tradeDesc }}</p>
          <self-line-chart
            ref="refCheckMarketCapitalTrade"
            id="checkMarketCapitalTrade"
            customClass="link_chart_hidden"
          />
        </div>
        <div>
          <h3>沪股通资金流入</h3>
          <p>{{ capitaldesc.extendDesc }}</p>
          <self-line-chart
            ref="refCheckMarketCapitalExtend"
            id="checkMarketCapitalExtend"
            customClass="link_chart_hidden"
          />
        </div>
      </div>
      <div ref="refCheckMarketTrendHolder" v-if="trendVisible" class="trend">
        <div>
          <h3>灯塔指数（全A）多空趋势</h3>
          <k-chart ref="refCheckMarketTrendKLine" id="checkMarketTrendKLine" customClass="k_chart" />
        </div>
        <div>
          <h3>灯塔指数（全A）涨幅趋势</h3>
          <p>{{ trendDesc.aIndex }}</p>
          <self-line-chart ref="refCheckMarketTrendIndex" id="checkMarketTrendIndex" customClass="link_chart_hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import Bar from "./bar.vue";
import Stat from "./stat.vue";
import doubleLineChart from "@/components/selfLineChart/double.vue";
import tableChart from "@/components/tableChart/index.vue";
import SemiCircle from "./semiCircle.vue";
import selfLineChart from "@/components/selfLineChart/hideXY.vue";
import KChart from "@/components/selfLineChart//kChart.vue";
export default {
  data: function () {
    return {
      titleText: null, // 动画句柄
      updateDate: "", // 数据更新日期
      totalData: [],
      barVisible: false, // 市场涨跌分布
      statVisible: false, // 涨跌强度
      lineVisible: false, // 涨跌走势
      tableVisible: false, // 封单量、涨跌幅
      circleVisible: false, // 大盘预警
      capitalVisible: false, // 市场资金
      capitaldesc: {},
      trendVisible: false,
      trendDesc: {},
    };
  },
  components: {
    Bar,
    Stat,
    doubleLineChart,
    tableChart,
    SemiCircle,
    selfLineChart,
    KChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#checkMarketText").addClass("hidden");
        this.barVisible = false;
        this.statVisible = false;
        this.lineVisible = false;
        this.tableVisible = false;
        this.circleVisible = false;
        this.capitalVisible = false;
        this.trendVisible = false;
        this.capitaldesc = {};
        this.updateDate = "";
        this.totalData = [];
        resolve();
      });
    },
    async show(commandData) {
      this.barVisible = false;
      this.statVisible = false;
      this.lineVisible = false;
      this.tableVisible = false;
      this.circleVisible = false;
      this.capitalVisible = false;
      this.trendVisible = false;
      //设置标题
      const title = commandData.data.pageTitle;
      $("#checkMarketPages").removeClass("hidden");
      $("#checkMarketText").html(title);
      this.$nextTick(async () => {
        $("#checkMarketText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#checkMarketText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (["当日涨停强度", "当日跌停强度"].indexOf(commandData.type) !== -1) {
          this.handleStat(commandData);
        } else if (["市场强度"].indexOf(commandData.type) !== -1) {
          this.handleBar(commandData);
        } else if (["涨跌停走势"].indexOf(commandData.type) !== -1) {
          this.handleLine(commandData);
        } else if (
          ["涨停板", "连续涨停", "涨停打开", "跌停板", "连续跌停", "跌停打开"].indexOf(commandData.type) !== -1
        ) {
          this.handleTable(commandData);
        } else if (["当日大盘预警"].indexOf(commandData.type) !== -1) {
          this.handleCircle(commandData);
        } else if (["当日市场资金"].indexOf(commandData.type) !== -1) {
          this.handleCapital(commandData);
        } else if (["当日市场趋势"].indexOf(commandData.type) !== -1) {
          this.handleTrend(commandData);
        }
      });
    },
    getSum(total, num) {
      return total + num;
    },
    setTotalData(data) {
      let value2 = data
        .filter((item) => item.type === 2)
        .map((item) => item.value)
        .reduce((v1, v2) => v1 + v2);
      let value1 = data
        .filter((item) => item.type === 1)
        .map((item) => item.value)
        .reduce((v1, v2) => v1 + v2);
      let value0 = data
        .filter((item) => item.type === 0)
        .map((item) => item.value)
        .reduce((v1, v2) => v1 + v2);
      let total = data.map((item) => item.value).reduce((v1, v2) => v1 + v2);
      this.totalData = [
        {
          name: "涨",
          value: value2,
          rate: `${((100 * value2) / total).toFixed(2)}%`,
        },
        {
          name: "平",
          value: value1,
          rate: `${((100 * value1) / total).toFixed(2)}%`,
        },
        {
          name: "跌",
          value: value0,
          rate: `${((100 * value0) / total).toFixed(2)}%`,
        },
      ];
    },
    handleBar(commandData) {
      this.barVisible = true;
      this.setTotalData(commandData.data.data);
      this.$nextTick(() => {
        // 渲染折线图
        this.renderBar(commandData);
      });
    },
    renderBar(commandData) {
      this.updateDate = commandData.data.date;
      let width = this.$refs.refCheckMarketBarHolder.clientWidth;
      this.$refs.refCheckMarketBarChart.barChart(commandData.data.data, {
        width: width,
        height: width * 0.6,
        marginTop: 20,
        marginRight: 85,
        marginBottom: 57,
        marginLeft: 10,
        duration: 300,
        delay: 14,
        ease: "easeCircleOut",
      });
    },
    handleStat(commandData) {
      this.statVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderStat(commandData);
      });
    },
    renderStat(commandData) {
      this.updateDate = commandData.data.date;
      this.$refs.refCheckMarketStat.init(commandData);
    },
    handleLine(commandData) {
      this.lineVisible = true;
      this.$nextTick(() => {
        // 渲染折线图
        this.renderLine(commandData);
      });
    },
    renderLine(commandData) {
      this.updateDate = commandData.data.date;
      let width = this.$refs.refCheckMarketLineHolder.clientWidth;
      this.$refs.refCheckMarketLine.selfLineChart(commandData.data.data, {
        width: width,
        height: width * 0.74,
        marginLeft: 0,
        marginRight: 60,
        marginTop: 40,
        marginBottom: 30,
        duration: 1000,
        delay: 20,
      });
    },
    handleTable(commandData) {
      this.tableVisible = true;
      this.$nextTick(() => {
        this.renderTable(commandData);
      });
    },
    renderTable(commandData) {
      let width = this.$refs.refCheckMarketTableHolder.clientWidth;
      this.$refs.refCheckMarketTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        // col4Suffix: "亿",
      });
      this.updateDate = commandData.data.date;
    },
    handleCircle(commandData) {
      this.circleVisible = true;
      this.$nextTick(() => {
        this.renderCircle(commandData);
      });
    },
    renderCircle(commandData) {
      this.$refs.refCheckMarketCircle.init(commandData);
    },
    handleCapital(commandData) {
      this.capitalVisible = true;
      this.$nextTick(() => {
        this.renderCapital(commandData);
      });
    },
    renderCapital(commandData) {
      let width = this.$refs.refCheckMarketCapitalHolder.clientWidth;
      let data = commandData.data.data.aMarginTrade.data.map((item) => {
        return {
          name: this.$moment(item.iTime).format("YYYY-MM-DD"),
          value: item.value,
        };
      });
      let data1 = commandData.data.data.aAHExtend.data.map((item) => {
        return {
          name: this.$moment(item.iTime).format("YYYY-MM-DD"),
          value: item.value,
        };
      });
      this.capitaldesc = {
        tradeDesc: commandData.data.data.aMarginTrade.desc,
        extendDesc: commandData.data.data.aAHExtend.desc,
      };
      this.$refs.refCheckMarketCapitalTrade.selfLineChart(data, {
        width: width,
        height: width * 0.38,
        marginLeft: 0,
        marginRight: 60,
        marginTop: 40,
        marginBottom: 80,
        duration: 800,
        delay: 10,
        yTick: 6,
        isDraw: false,
        unit: "",
        xTickNumber: 5,
      });
      this.$refs.refCheckMarketCapitalExtend.selfLineChart(data1, {
        width: width,
        height: width * 0.38,
        marginLeft: 0,
        marginRight: 60,
        marginTop: 40,
        marginBottom: 80,
        duration: 800,
        delay: 10,
        yTick: 6,
        isDraw: false,
        unit: "",
        xTickNumber: 5,
      });
    },
    handleTrend(commandData) {
      this.trendVisible = true;
      this.$nextTick(() => {
        this.renderTrend(commandData);
      });
    },
    renderTrend(commandData) {
      let width = this.$refs.refCheckMarketTrendHolder.clientWidth;
      let data = commandData.data.data.aIndex.data.map((item) => {
        return {
          name: this.$moment(item.iTime).format("YYYY-MM-DD"),
          value: item.value,
        };
      });
      this.trendDesc = {
        aIndex: commandData.data.data.aIndex.desc,
      };
      let kData = commandData.data.data.kLine.map((item) => {
        return {
          label: item[0],
          k: item.slice(1, 5),
          iBs: item[item.length - 1],
        };
      });
      this.$refs.refCheckMarketTrendKLine.KLineChart(kData, {
        width: width,
        height: width * 0.38,
        marginTop: 20,
        marginBottom: 50,
        marginLeft: 0,
        marginRight: 70,
        duration: 40,
        delay: 6,
        ease: "easeCubicInOut",
      });
      this.$refs.refCheckMarketTrendIndex.selfLineChart(data, {
        width: width,
        height: width * 0.38,
        marginTop: 50,
        marginBottom: 80,
        marginLeft: 0,
        marginRight: 70,
        duration: 800,
        delay: 20,
        yTick: 6,
        isDraw: false,
        unit: "",
        xTickNumber: 5,
      });
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
