<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async selfLineChart(
      data,
      {
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        yDomain,
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
      } = {}
    ) {
      const X = data.map((d) => d.name);
      if (xDomain === undefined) xDomain = X;

      const maxData1 = d3.max(data, (d) => d.value1)
      const maxData2 = d3.max(data, (d) => d.value2)
      if (yDomain === undefined) yDomain = [0, Math.ceil(d3.max([maxData1, maxData2]))]

      var widthRatio = X.length / 242;
      // 比例尺
      const xScale = d3.scalePoint(xDomain, [marginLeft, (width - marginRight) * widthRatio]);
      const yScale = d3.scaleLinear(yDomain, [height - marginBottom, marginTop]);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const drawXY = () => {
        const xAxis =d3.axisBottom(xScale).tickSize(0).tickSizeOuter(0).tickValues([X[0], X[X.length - 1]]);
        const yAxis = d3.axisRight(yScale).tickSize(0).ticks(4);
      // x轴
      const axisX = svg
        .append("g")
        .attr("class", "axis_x_bottom")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0)
          g.selectAll(".tick").attr("class", (d,i) => {
            return `tick x_tick_${i}`
          })
          g.selectAll(".tick text")
            .attr("class", (d, i) => {
              return `x_text x_text_${i}`
            })
            .attr("dy", "1em")
            .attr("opacity", 0);
          g.selectAll(".x_text_1").text("15:00")
          g.selectAll(".x_tick_1").attr("transform", `translate(${ width - marginRight }, 0)`)
        });
        axisX
          .selectAll(".x_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(600)
          .attr("opacity", 1);
      // y轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y_right")
        .attr("transform", `translate(${width - marginRight},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", -(width - marginLeft - marginRight))
            .attr("stroke", "#C5C5C5")
            .attr("class", "tick_long_line")
            .attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "y_text")
          .attr("dx", "0.3em")
          .attr("opacity", 0);
        });
        axisY.selectAll(".tick_long_line").transition().duration(600).attr("opacity", 1);
        axisY
          .selectAll(".y_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(600)
          .attr("opacity", 1);
      }
      drawXY()

       //画线
      const drawLine = (value, lineColor) => {
        const lineGroup = svg.append("g").attr("class", "line_group");
        const pathLine = d3.line().curve(d3[curveName]).x((i) => xScale(X[i])).y((i) => yScale(value[i]));
        const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", 'line')
            .attr("stroke", lineColor)
            .attr("stroke-width", "2")
            .attr("d", pathLine(d3.range(value.length)));
        const svgLineTotalLength = svgLine.node().getTotalLength();
        svgLine
            .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
      };
      drawLine(data.map((d) => d.value1), '#FF3B30');
      drawLine(data.map((d) => d.value2), '#39B24E');
      

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
