<template>
    <div :class="customClass">
        <ul>
            <li v-for="(item, index) in data" :key="index" class="plate_item">
                <div class="text">
                    <span :style="{ color: currentType === '当日跌停强度' ? '#39B24E' : '#FF3B30'}">{{ item.today }}</span>
                    <span>当日</span>
                </div>
                <div class="line" style="marginRight: -30px"></div>
                <div class="ball" :style="{ backgroundColor: currentType === '当日跌停强度' ? '#39B24E' : '#FF3B30'}">
                    {{ item.name }}
                </div>
                <div class="line" style="marginLeft: -30px"></div>
                <div class="text">
                    <span :style="{ color: currentType === '当日跌停强度' ? '#39B24E' : '#FF3B30'}">{{ item.yesterday }}</span>
                    <span>前一日</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
export default {
    props: ['customClass'],
    data: function() {
        return {
            data: [],
            currentType: '',
        }
    },
    methods: {
        init(commandData) {
            const data = commandData.data.data
            this.currentType = commandData.type
            if (commandData.type === '当日涨停强度') {
                this.data = [
                    {
                        name: '涨停板',
                        today: data.todayMaxLimit,
                        yesterday: data.yesterdayMaxLimit
                    },
                    {
                        name: '连续涨停',
                        today: data.todayContiMaxLimit,
                        yesterday: data.yesterdayContiMaxLimit
                    },
                    {
                        name: '涨停打开',
                        today: data.todayOpenMaxLimit,
                        yesterday: data.yesterdayOpenMaxLimit
                    }
                ]
            } else {
                this.data = [
                    {
                        name: '跌停板',
                        today: data.todayMinLimit,
                        yesterday: data.yesterdayMinLimit
                    },
                    {
                        name: '连续跌停',
                        today: data.todayContiMinLimit,
                        yesterday: data.yesterdayContiMinLimit
                    },
                    {
                        name: '跌停打开',
                        today: data.todayOpenMinLimit,
                        yesterday: data.yesterdayOpenMinLimit
                    },
                ]
            }
            this.$nextTick(() => {
                this.ffRow = anyElement({
                    targets: document.querySelectorAll(".plate_item"),
                    isSplitTxt: false,
                    duration: 1000,
                    delay: 500,
                    easing: "easeOutExpo",
                    inEffect: "topIn",
                    outEffect: "leftOutBig",
                });
                this.ffRow.runIn();
            })
        }
    }
}
</script>