<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async barChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 40, // the right margin, in pixels
        marginBottom = 40, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain,
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeCircleOut", //元素之间间隔时长
      } = {}
    ) {
      const X = data.map((d) => d.name);
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, d3.max(data, (d) => d.value)];

      // 比例尺
      const xScale = d3.scaleBand(xDomain, xRange).padding(0.2);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const drawXY = () => {
        const xAxis = d3.axisBottom(xScale).tickSize(0).tickSizeOuter(0);
        const yAxis = d3.axisRight(yScale).tickSize(0).ticks(6);
        // x轴
        const axisX = svg
          .append("g")
          .attr("class", "axis_x_bottom")
          .attr("transform", `translate(0,${height - marginBottom + 1})`)
          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("opacity", 0);
            g.selectAll(".tick text")
              .attr("class", (d, i) => {
                return `x_text x_text_${data[i].type}`;
              })
              .attr("dy", "1.5em")
              .attr("opacity", 0);
          });
        axisX
          .selectAll(".x_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(200)
          .attr("opacity", 1);
        // y轴
        const axisY = svg
          .append("g")
          .attr("class", "axis_y_right")
          .attr("transform", `translate(${width - marginRight},0)`)
          .call(yAxis)
          .call((g) => {
            g.select(".domain").attr("opacity", 0);
            g.selectAll(".tick line")
              .clone()
              .attr("x2", -(width - marginLeft - marginRight))
              .attr("stroke", "#C5C5C5")
              .attr("class", "tick_long_line")
              .attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "y_text").attr("dx", "0.3em").attr("opacity", 0);
          });
        axisY.selectAll(".tick_long_line").transition().duration(200).attr("opacity", 1);
        axisY
          .selectAll(".y_text")
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3.easeCircleOut)
          .duration(200)
          .attr("opacity", 1);
      };
      drawXY();

      const drawBar = () => {
        const bars = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (d, i) => {
            return xScale(d.name);
          })
          .attr("y", (d, i) => {
            return yScale(yDomain[0]);
          })
          .attr("height", 0)
          .attr("width", xScale.bandwidth())
          .attr("class", (d, i) => {
            return `bar bar${d.type}`;
          })
          .transition()
          .delay((d, i) => i * delay)
          .ease(d3[ease])
          .duration(duration)
          .attr("y", (d, i) => {
            return yScale(d.value);
          })
          .attr("height", (d, i) => {
            return yScale(0) - yScale(d.value);
          });
      };
      drawBar();
      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
